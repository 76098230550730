a:hover,
a.route-active {
    text-decoration: none;
    border-bottom: 1px solid map-get($theme-colors, "darker");
}

a.text-white:hover,
a.text-white.route-active {
    border-bottom: 1px solid white;
}

.mono-font {
    font-family: monospace;
}

html {
    min-height: 100vh;
}

html,
body,
#app,
#js-router-wrapper {
    height: 100%;
}

#js-router-wrapper {
    display: flex;
    flex-direction: column;
    main {
        flex-grow: 1;
    }
}

header nav {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.33);
    .list-inline-item:not(:last-child) {
        &::after {
            content: "|";
            margin-left: 0.5rem;
        }
    }
}

.legend {
    list-style: square;
}

footer {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.33);
    .version-number {
        font-family: monospace;
    }
}

.logo-wrapper {
    width: 275px;
}

.login {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.33);
}

.subtitle {
    font-size: 24px;
    font-family: "apercu-bold-pro", sans-serif;
    text-transform: uppercase;
}

.messages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1291;
    .toast {
        width: 100%;
        //transform: translate(-50%);
        //left: 50%;
        right: 1rem;
        top: 9.5rem;
    }
    .message-color {
        width: 1rem;
        height: 1rem;
    }
}

.sorted-header::after {
    content: " ↓";
}

.sorted-header-reverse::after {
    content: " ↑";
}

.status-bubble {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}

.input-group > .rbt {
    flex: 1;
}

.header-spacer {
    padding-top: 30px;
}

.new-button {
    margin-top: -8px;
}

.super {
    padding-left: 0.2em;
    font-size: 0.8em;
    vertical-align: super;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-big {
    font-size: 1.2em;
}

.date-picker-width {
    width: 142px !important;
}

.printonly {
    display: none;
}

@page {
    //size: A4;
    margin-top: 0; margin-bottom: 0;
    margin-left: 10mm;
    margin-right: 0mm;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
        font-size: 12pt;
    }
    h1 {
        font-size: 30pt;
    }
    .text-big {
        font-size: 15pt;
    }
    .report-detail {
        margin-top: 2rem;
        // margin-bottom: 112px;
    }
    *, .font-weight-bold {
        font-weight: normal !important;
    }
    .no-print {
        display: none !important;
    }
    .printonly {
        display: block;
    }
    .pdf-footer {
        // position: fixed;
        // bottom: 0;
        // width: 100%;
        // position: fixed;
        // bottom: 10mm;
    }
    p, dd {
        margin-bottom: 0.25rem;
    }
    dl {
        margin-bottom: 0.5rem;
    }
    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    address {
        text-align: center;
    }
    .print-small {
        font-size: 1.5rem;
    }
    .report-detail {
        height: 100%;
        position: relative;
    }

    // .title-block {
    //     width: 100%;
    //     display: flex;
    //     align-items: flex-start;
    //     p {
    //         margin-left: 2rem;
    //     }
    // }   
}

.alert {
    display: none;
}

.alert.show {
    display: block;
}

.w-login {
    width: 360px;
}

.add-multi {
    display: none;
    &:last-of-type {
        display: block;
    }
}

.resource-form.offer .temporal-fields {
    display: none;
}

.link-muted {
    color: rgba(255, 255, 255, 0.33);
}

.report-detail {
    p.text-big {
        margin-bottom: 0.25rem;
    }
    p, dd {
        margin-bottom: 0.25rem;
    }
}

.no-wrap {
    white-space: nowrap;
}

.inline-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
}